<template>
  <div>
    <div>
      <v-row>
        <v-col class="d-flex align-center justify-start">
          <v-btn-toggle
            :value="mode"
            mandatory
            color="secondary"
            dense
            group
            @change="(mode) => $emit('mode-change', mode)"
          >
            <v-btn value="day">
              {{ $t('messages.views.admin.components.dashboard.crossroadModal.window.stats.mode.day') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-col class="d-flex align-center justify-center">
          <span class="mx-5">
            <o-select
              v-model="day"
              :items="days"
              item-text="name"
              item-value="number"
              dense
              prepend-icon="mdi-calendar-today"
            />
          </span>
        </v-col>

        <v-col class="d-flex align-center justify-end">
          <v-btn-toggle
            v-model="dataType"
            color="secondary"
            dense
            group
          >
            <v-btn value="TYPE_OCCUPANCY">
              <v-icon
                size="20"
                class="mr-1"
              >
                mdi-car-multiple
              </v-icon>
              {{ $t('messages.views.admin.components.dashboard.crossroadModal.window.stats.occupancy') }}
            </v-btn>
            <v-btn value="TYPE_LANE_COUNTING">
              <v-icon
                size="20"
                class="mr-1"
              >
                mdi-car-3-plus
              </v-icon>
              {{ $t('messages.views.admin.components.dashboard.crossroadModal.window.stats.counting') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </div>

    <v-row>
      <v-col
        v-if="dataType === 'TYPE_LANE_COUNTING'"
        cols="6"
      >
        <o-card-stat
          class="fill-height stats-card elevation-0 rounded-lg"
          outlined
          :title="$t('messages.views.admin.components.dashboard.crossroadModal.window.stats.countingEvolution')"
          :loading="loadingStats"
          :has-data="hasData(stats.counting)"
          @download-chart-as-csv="() => downloadChartAsCsv('countingEvolution')"
          @download-chart-as-png="() => downloadChartAsPng('countingEvolution')"
        >
          <template
            v-slot:default="{ expanded }"
            class="fill-height fill-width"
          >
            <o-chart-counting-evolution
              ref="countingEvolutionChart"
              class="fill-height fill-width"
              :labels="chartLabels"
              :data="stats.counting"
              :counters="computedCounters"
              :height="expanded ? 1000 : chartHeight"
              :selected-counter-id="selectedCounterId"
            />
          </template>
        </o-card-stat>
      </v-col>
      <v-col
        v-if="dataType === 'TYPE_OCCUPANCY'"
        cols="6"
      >
        <o-card-stat
          class="fill-height stats-card elevation-0 rounded-lg"
          outlined
          :title="$t('messages.views.admin.components.dashboard.crossroadModal.window.stats.occupancyEvolution')"
          :loading="loadingStats"
          :has-data="hasData(stats.occupancy)"
          @download-chart-as-csv="() => downloadChartAsCsv('occupancyEvolution')"
          @download-chart-as-png="() => downloadChartAsPng('occupancyEvolution')"
        >
          <template
            v-slot:default="{ expanded }"
            class="fill-height fill-width"
          >
            <o-chart-occupancy-evolution
              ref="occupancyEvolutionChart"
              class="fill-height fill-width"
              :labels="chartLabels"
              :data="stats.occupancy"
              :counters="computedCounters"
              :height="expanded ? 1000 : chartHeight"
              :selected-counter-id="selectedCounterId"
            />
          </template>
        </o-card-stat>
      </v-col>
      <v-col
        cols="6"
      >
        <o-card-counters
          v-model="selectedCounterId"
          class="fill-height stats-card elevation-0 rounded-lg"
          outlined
          :counters="loadingCrossroad ? [] : computedCounters"
          :sensors="loadingCrossroad ? [] : computedSensors"
          :loading="loadingCrossroad"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { downloadCanvasAsPng, downloadDataAsCsv } from '@/utils/download';
  import { getChartDefaultColors } from '@utils/color';
  const moment = require('moment-timezone');

  export default {
    props: {
      value: {
        type: [Number, String],
        required: true,
      },
    },

    apollo: {
      crossroad: {
        query: require('@gql/views/admin/components/dashboard/crossroad-modal/window/stats/getCrossroad.gql'),
        variables: function () {
          return {
            id: this.value,
          };
        },
        client: 'stations',
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        result (res, key) {
          this.loadingCrossroad = res.loading;
        },
        update (data) {
          data.getCrossroad.counters.sort((a, b) => a.number > b.number);

          const colors = getChartDefaultColors(data.getCrossroad.counters.length);
          for (const index in data.getCrossroad.counters) {
            data.getCrossroad.counters[index].color = colors[index];
          }

          return data.getCrossroad;
        },
      },

      data: {
        query: require('@gql/views/admin/components/dashboard/crossroad-modal/window/stats/statsByDay.gql'),
        skip: function () {
          return !this.crossroad;
        },
        variables: function () {
          return {
            timezone: moment.tz.guess(),
            from: this.from.clone().utc().format('Y-MM-DD HH:mm:ss'),
            to: this.to.clone().utc().format('Y-MM-DD HH:mm:ss'),
            day: this.day,
            step: this.step,
            occupancyCounterIds: this.getCounterIds('TYPE_OCCUPANCY'),
            countingCounterIds: this.getCounterIds('TYPE_LANE_COUNTING'),
            withOccupancy: this.getCounterIds('TYPE_OCCUPANCY').length > 0,
            withCounting: this.getCounterIds('TYPE_LANE_COUNTING').length > 0,
          };
        },
        client: 'stats',
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        result (res, key) {
          this.loadingStats = res.loading;
        },
        update: function (data) {
          this.stats.counting = data.counting
            ? this.statsToChartData(data.counting)
            : []
          ;
          this.stats.occupancy = data.occupancy
            ? this.statsToChartData(this.convertStatsToPercentage(data.occupancy, 60))
            : []
          ;
        },
      },
    },

    data: function () {
      return {
        selectedCounterId: null,
        from: moment().subtract(1, 'year').set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
        to: moment().add(1, 'day').set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
        day: moment().day(),
        dataType: 'TYPE_OCCUPANCY',
        chartHeight: 280,
        mode: 'day',
        loadingCrossroad: true,
        loadingStats: true,
        stats: {
          counting: null,
          occupancy: null,
        },
        counters: {
          headers: [
            {
              text: '',
              value: 'legend',
              sortable: false,
              align: 'center',
            },
            {
              text: this.$t('labels.counter.number'),
              value: 'number',
              sortable: false,
              align: 'center',
            },
            {
              text: this.$t('labels.counter.sensors'),
              value: 'sensors',
              sortable: false,
              align: 'center',
            },
          ],
        },
      };
    },

    computed: {
      days () {
        const days = [];
        for (let i = 1; i <= 7; i++) {
          days.push({
            name: this.$t('constants.days.' + i),
            number: i,
          });
        }

        return days;
      },

      computedCounters () {
        if (!this.crossroad) {
          return [];
        }

        return this.crossroad.counters
          .filter((counter) => counter.type === this.dataType)
        ;
      },

      computedSensors () {
        const ids = [];

        for (const counter of this.computedCounters) {
          for (const sensorId of counter.sensors) {
            if (!ids.includes(sensorId)) {
              ids.push(sensorId);
            }
          }
        }

        const sensors = [];
        for (const sensorId of ids) {
          const sensor = this.crossroad.station.sensors.find((sensor) => sensor.id === sensorId);
          sensors.push(sensor);
        }

        return sensors;
      },

      step () {
        switch (this.mode) {
          case 'day':
            return 30;
          default:
            throw new Error('Invalid mode');
        }
      },

      times () {
        const times = [];
        const endingTimeStamp = 24 * 60 * 60;
        let currentTimestamp = 0;

        while (currentTimestamp < endingTimeStamp) {
          times.push(currentTimestamp);
          currentTimestamp += this.step * 60;
        }

        return times;
      },

      chartLabels () {
        return this.times.map((time) => {
          time = parseInt(time);
          const hours = Math.floor(time / (60 * 60));
          const minutes = Math.floor(time / 60) - hours * 60;

          return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
        });
      },
    },

    watch: {
      computedCounters (newValue) {
        this.selectedCounterId = null;
      },
    },

    methods: {
      downloadChartAsCsv (type) {
        const headers = [];
        switch (type) {
          case 'occupancyEvolution':
          case 'countingEvolution':
            headers.push(this.$t('messages.views.admin.components.dashboard.crossroadModal.window.stats.csv.headers.time'));

            for (const counter of this.computedCounters) {
              headers.push(this.$t('messages.views.admin.components.dashboard.crossroadModal.window.stats.csv.headers.counter', {
                number: counter.number,
              }));
            }
            break;
        }

        const data = [];

        switch (type) {
          case 'occupancyEvolution':
            for (const i in this.chartLabels) {
              const values = [];
              for (const counter of this.computedCounters) {
                values.push(this.stats.occupancy[counter.id] !== undefined
                  ? this.stats.occupancy[counter.id][i]
                  : null
                );
              }

              data.push([
                this.chartLabels[i],
                ...values,
              ]);
            }
            break;
          case 'countingEvolution':
            for (const i in this.chartLabels) {
              const values = [];
              for (const counter of this.computedCounters) {
                values.push(this.stats.counting[counter.id] !== undefined
                  ? this.stats.counting[counter.id][i]
                  : null
                );
              }

              data.push([
                this.chartLabels[i],
                ...values,
              ]);
            }
            break;
        }

        downloadDataAsCsv('counters_' + type, headers, data);
      },

      downloadChartAsPng (type) {
        const canvas = this.$refs[type + 'Chart'].$refs.canvas;
        downloadCanvasAsPng('section_' + type, canvas);
      },

      statsToChartData (stats) {
        const data = {};

        for (const item of stats) {
          const time = parseInt(item.time);
          const entityId = parseInt(item.entityId);
          const value = parseFloat(item.value);

          if (data[entityId] === undefined) {
            data[entityId] = Array(this.times.length).fill(null);
          }

          const index = this.times.indexOf(time);
          data[entityId][index] = value;
        }

        return data;
      },

      convertStatsToPercentage (stats, max) {
        for (const item of stats) {
          const value = parseFloat(item.value);
          item.value = Math.round(value / max * 100);
        }

        return stats;
      },

      hasData (values) {
        if (this.loadingStats) {
          return true;
        }

        for (const counterId of Object.keys(values)) {
          for (const value of values[counterId]) {
            if (value !== null) {
              return true;
            }
          }
        }

        return false;
      },

      getCounterIds (type) {
        return this.crossroad.counters
          .filter((counter) => counter.type === type)
          .map((counter) => counter.id)
        ;
      },
    },
  };
</script>

<style scoped lang="sass">
  .stats-card
    height: 358px !important
</style>
